console.log("Hi From Birds Project JS");

import initAppHeight from "./scripts/appHeight";
import initBurger from "./scripts/initBurger";
import initFchicken from "./scripts/initFchicken";
import initGallery from "./scripts/initGallery";
import initHeroAnimation from "./scripts/initHeroAnimation";
import initTestimonial from "./scripts/initTestimonial";
// import initHoverEffect from "./scripts/hoverEffect";
// import initImageTrail from "./scripts/imageTrail";
// import initLoader from "./scripts/loader";
import initScrollMarque from "./scripts/scrollMarque";
// import initSuffleEffect from "./scripts/shuffle";
import initSmoothScroll from "./scripts/smoothScroll";
// // import initTimeZone from "./scripts/timeZone";
// // import initVimeoPlayPauze from "./scripts/vimeoPlayer";
// import initDropdown from "./scripts/dropdown";
// import initScrambleText from "./scripts/scrambleText";

initAppHeight();
// initSmoothScroll();
initHeroAnimation();
initBurger();
// initLoader();
// initScrambleText();
// initSmoothScroll();
initGallery();
initScrollMarque();
// initDropdown();
// initHoverEffect();
// initSuffleEffect();
// initImageTrail();

initTestimonial();
initFchicken();
