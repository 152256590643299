import $ from "jquery";
// import Swiper from "swiper";
// import "swiper/css";
import "@splidejs/splide/css";

import Splide from "@splidejs/splide";
import { AutoScroll } from "@splidejs/splide-extension-auto-scroll";

function initGallery() {
  const splide = new Splide(".splide", {
    type: "loop",
    drag: "free",
    autoScroll: {
      speed: 1,
    },
  });
  splide.mount({ AutoScroll });
}

export default initGallery;
