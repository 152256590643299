import gsap from "gsap";
// import gsapCore from "gsap/gsap-core";
import ScrollTrigger from "gsap/ScrollTrigger";
import $ from "jquery";
gsap.registerPlugin(ScrollTrigger);

function initBurger() {
  if (!$(".burger").length) {
    return;
  }

  const target = document.querySelector(".burger");
  const parallaxDivs = target.querySelectorAll("[data-parallax]");

  gsap.from(parallaxDivs, {
    autoAlpha: 0,
    duration: 0.3,
    stagger: 0.2,
    scrollTrigger: {
      trigger: target,
      start: "top center",
    },
  });

  parallaxDivs.forEach((div) => {
    const speed = parseFloat(div.getAttribute("data-speed"));

    gsap.to(div, {
      y: () => `-${(div.offsetHeight * speed) / 2}px`,
      ease: "none",
      scrollTrigger: {
        trigger: target,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        // markers: true,
      },
    });
  });
}
export default initBurger;
