import gsap from "gsap";
import $ from "jquery";

function initHeroAnimation() {
  // HERO IMAGE ANIMATION
  const heroTl = gsap.timeline({
    scrollTrigger: {
      trigger: ".hero",
      scrub: true,
      start: "top top",
      end: "+=150%",
      // end: "bottom",
      // markers: true,
      duration: 2,
    },
  });
  heroTl.to(".hero img", {
    y: 150,
  });

  $(".hero__btn ").mouseover(function () {
    $(".img__anm__wrapper").addClass("is-active");
    $("body").addClass("no-scroll");
  });
  $(".hero__btn ").mouseleave(function () {
    $(".img__anm__wrapper").removeClass("is-active");
    $("body").removeClass("no-scroll");
  });

  let n;
  let ms;
  $(".img__anm__item").each(function (index) {
    n = index * 2;
    ms = n * 100;

    $(this).css("animation-delay", `${ms}ms`);
  });

  $(".img__anm__wrapper .img__anm__item").css("animation-duration", `${ms}ms`);
}

export default initHeroAnimation;
