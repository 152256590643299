import gsap from "gsap";
// import gsapCore from "gsap/gsap-core";
import ScrollTrigger from "gsap/ScrollTrigger";
import $ from "jquery";
gsap.registerPlugin(ScrollTrigger);

function initFchicken() {
  if (!$(".f-chicken").length) {
    return;
  }

  const target = document.querySelector(".f-chicken");
  const parallaxDivs = target.querySelectorAll("[data-parallax]");

  gsap.from(parallaxDivs, {
    // scale: 0,
    autoAlpha: 0,
    ease: "Power2.easeOut",
    duration: 0.3,
    stagger: 0.2,
    scrollTrigger: {
      trigger: target,
      start: "top center",
      scrub: false,
      // markers: true,
    },
  });

  parallaxDivs.forEach((div) => {
    const speed = parseFloat(div.getAttribute("data-speed"));

    gsap.to(div, {
      y: () => `-${(div.offsetHeight * speed) / 2}px`,
      ease: "none",
      scrollTrigger: {
        trigger: target,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        // markers: true,
      },
    });
  });
}
export default initFchicken;
